import { AddToWishlistService } from './AddToWishlistService.js';
import { ToggleBox } from '../toggles/toggleBox.js';
import { AdjustTracking } from './AdjustTracking.js';
import { FirebaseTracking } from './FirebaseTracking.js';

window.o_wishlistApi = window.o_wishlistApi || {};

window.o_global.eventLoader.onLoad(10, () => {
    if (!window.o_wishlistApi.initialized) {
        const toggles = new ToggleBox(document.querySelector('.wl_toggleInfo'));
        const adjustTracking = new AdjustTracking();
        const firebaseTracking = new FirebaseTracking();
        new AddToWishlistService(adjustTracking, firebaseTracking, toggles).init();
        window.o_wishlistApi.initialized = true;
    }
});
