/**
 *
 */
class AdjustTracking {
    trackAdjust(articleNumber, variationLink) {
        if (window?.o_apps?.runningInApp?.() && articleNumber && variationLink) {
            window.o_global.eventQBus.emit('ft-apps.adjust.eventTracking', {
                eventName: {
                    android: '8sra7u',
                    ios: 'izn9z9'
                },
                partnerParameters: {
                    articleids: '["' + articleNumber + '"]',
                    contentType: 'product',
                    sitePath: window.location.protocol + '/*                                      */
                }
            });
        }
    }
}

export { AdjustTracking };
