/**
 *
 */
class FirebaseTracking {
    trackFirebase(articleNumber) {
        if (window?.o_apps?.runningInApp?.() && articleNumber) {
            window.o_global.eventQBus.emit('ft-apps.firebase.eventTracking', {
                event_name: 'add_to_wishlist',
                event_params: { item_id: articleNumber, quantity: 1 }
            });
        }
    }
}

export { FirebaseTracking };
